import {
  Box,
  Heading,
  Link,
  SimpleGrid,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { BlogShort } from 'graphqlTypes'
import BlogCard from 'components/BlogCard'

interface Data {
  allContentfulPost: {
    edges: Array<{
      node: BlogShort
    }>
  }
}

export default function BlogPage(props: Data) {
  const data = props.allContentfulPost;
  return (
    <Box as="section" bg={mode('#FFFFFF', 'gray.800')} py={{ base: '10', sm: '24' }}>
      <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Heading size="xl" mb="8" fontWeight="extrabold">
          Featured Articles
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing="12"
          mb="10"
          maxW="7xl"
        >
          {data && data.edges.map((blog) => (
            <BlogCard data={blog.node} key={blog.node.slug} />
          ))}
        </SimpleGrid>
        <Link fontSize="xl" fontWeight="bold" color={mode('brand.500', 'brand.500')} href='/blog'>
          <span>View all articles</span>
          <Box as={BsArrowRight} display="inline-block" ms="2" />
        </Link>
      </Box>
    </Box>
  )
}