import React from 'react'
import {
  Box,
  Flex,
  Heading,
  Img,
  LinkBox,
  LinkOverlay,
  Text,
  useColorModeValue as mode,
  Avatar,
  HStack,
} from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import { BsClockFill } from 'react-icons/bs'
import { Link } from 'gatsby'
import { BlogShort } from 'graphqlTypes'

interface BlogCardProps {
  data: BlogShort
}

const BlogCard: React.FC<BlogCardProps> = ({ data }) => {
  return (
    <LinkBox
      as="article"
      bg={{ sm: mode('white', 'gray.700') }}
      shadow={{ sm: 'base' }}
      rounded={{ sm: 'md' }}
      overflow="hidden"
      transition="all 0.2s"
      _hover={{ shadow: { sm: 'lg' } }}
    >
      <Flex direction="column">
        {data.heroImage ? (
          <Img
            height="60"
            objectFit="cover"
            alt={data.title.title}
            src={data.heroImage.fixed.src}
            srcSet={data.heroImage.fixed.srcSet}
          />
        ) : (
          <Box height="60"></Box>
        )}
        <Flex direction="column" px={{ sm: '6' }} py="5">
          <Heading as="h3" size="sm" mb="2" lineHeight="base">
            <LinkOverlay as={Link} to={`/blog/${data.slug}`} noOfLines={1}>
              {data.title.title}
            </LinkOverlay>
          </Heading>
          <Text noOfLines={2} mb="8" color={mode('gray.600', 'gray.400')}>
            {isEmpty(data?.description.description)
              ? ''
              : data.description.description}
          </Text>
          <Flex
            align="baseline"
            justify="space-between"
            fontSize="sm"
            color={mode('gray.600', 'gray.400')}
          >
            <HStack alignItems="center">
              {data.author && (
                <Avatar
                  size="2xs"
                  name={data.author.name}
                  src={data.author.avatar.fixed.src}
                  srcSet={data.author.avatar.fixed.srcSet}
                />
              )}
              {data.author && <Box as="a">{data.author.name}</Box>}
            </HStack>

            <Box href="#">
              <Box
                as={BsClockFill}
                display="inline-block"
                me="2"
                opacity={0.4}
              />
              {data.createdAt}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </LinkBox>
  )
}

export default BlogCard
